exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-tagged-blog-page-js": () => import("./../../../src/templates/TaggedBlogPage.js" /* webpackChunkName: "component---src-templates-tagged-blog-page-js" */)
}

